import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
// import { Cell } from 'styled-css-grid'

import {
  Grid,
  Cell,
  Typography,
  SEO,
  Page,
  Container,
  P5Wrapper,
  P5Sketch,
  Link,
  Divider,
} from '~/components'
import IndexLayout from '~/layouts'
import { SEOProps } from '~/components/SEO'

const BioCell = styled.div`
  grid-column: 1 / span 3;
  order: 2;
  @media (min-width: ${(p) => p.theme.breakpoints.lg}em) {
    grid-column: 1;
    order: 1;
  }
`

const P5Cell = styled.div`
  margin-top: 0;
  grid-column: 1 / span 3;
  order: 1;
  @media (min-width: ${(p) => p.theme.breakpoints.lg}em) {
    margin-top: 88px; // to visually align
    grid-column: 2 / span 2;
    order: 2;
  }
`

const StyledTypography = styled(Typography)`
  max-width: 100%;
  margin-bottom: ${(p) => p.theme.spacings[5]}rem;

  @media (min-width: ${(p) => p.theme.breakpoints.md}em) {
    max-width: 75%;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.lg}em) {
    max-width: 50%;
  }
`

const InfoPage = () => {
  const seo: SEOProps = {
    title: 'Information',
    path: '/info',
    article: false,
  }

  const theme = useContext(ThemeContext)

  return (
    <IndexLayout>
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image}
        path={seo.path}
        article={seo.article}
      />
      <Page>
        <Container>
          <Grid columnGap={`${theme.grid.gapLg}px`} columns={3}>
            <BioCell>
              <Typography variant="h1" marginBottom={3}>
                Me
              </Typography>
              <Typography>
                As the story goes, my journey as a "technical creative" began
                out of the desire to bring my earliest ideas to life. Rewind
                back to 1999, the web was young, and I was busy soaking up all
                the knowledge I could in order to build my first ever web
                presence. Ultimately, I learned <em>how to learn</em>, how to
                debug, how to launch my ideas and iterate on them. Ever since
                then, the majority of my time has been spent in that loop.
              </Typography>
              <Typography>
                Today I'm a professional multidisciplinary Designer and UX
                Engineer. I'm actively involved in creating print, web,
                branding, and interactive experiences. With my background, I get
                to collaborate with clients and teams alike, bridging the gap
                between the design and engineering disciplines.
              </Typography>
              <Typography>
                I've had the opportunity to learn from some of the best
                collegues, gain experience as a manager and lead projects every
                step of the way from discovery to delivery.
              </Typography>
              <Typography>And so, the journey continues...</Typography>
            </BioCell>
            <P5Cell>
              <P5Wrapper sketch={P5Sketch} />
            </P5Cell>
          </Grid>
          <Divider />
          <Grid columns="repeat(auto-fit, minmax(264px, 1fr))">
            <Cell>
              <Typography variant="h5" marginBottom={1}>
                Expertise
              </Typography>
              <ul>
                <li>Product Design</li>
                <li>User Research</li>
                <li>Design Operations</li>
                <li>Design Systems</li>
                <li>Prototyping</li>
                <li>Graphic Design</li>
                <li>Branding &amp; Art Direction</li>
                <li>Software Development</li>
                <li>A/B Testing</li>
                <li>Agile/Lean/Scrum Methodologies</li>
              </ul>
            </Cell>
            <Cell>
              <Typography variant="h5" marginBottom={1}>
                Interests
              </Typography>
              <ul>
                <li>Hardware Hacking</li>
                <li>3D Printing</li>
                <li>PC Gaming</li>
                <li>PC Fabrication</li>
                <li>Street Photography</li>
              </ul>
            </Cell>
            <Cell>
              <Typography variant="h5" marginBottom={1}>
                Industries
              </Typography>
              <ul>
                <li>Education</li>
                <li>E-Sports</li>
                <li>Sports &amp; Lifestyle</li>
                <li>Music Entertainment</li>
                <li>Fashion, Art &amp; Design</li>
              </ul>
            </Cell>
            <Cell>
              <Typography variant="h5" marginBottom={1}>
                Education
              </Typography>
              <Typography>
                2009 · Valedictorian, Bachelor of Science · Full Sail University
                · Winter Park, Florida
              </Typography>
            </Cell>
          </Grid>
          <Divider />
          <section>
            <Typography variant="h2">Press</Typography>
            <ul>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  <Link to="https://youtu.be/DtQQa2Hiw7s?t=275">
                    2021 Full Sail Tech Tuesday - "Leaders in Tech" Interview
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  <Link to="https://www.fullsail.edu/about/full-sail-stories/this-web-design-grad-combines-education-and-design">
                    2019 Full Sail Stories Interview
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  <Link to="https://www.abstract.com/blog/designing-learning-experiences/">
                    2017 Designing cutting-edge learning experiences with
                    Abstract.
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  <Link to="http://www.prweb.com/releases/2017/hackcelerator/prweb14602881.htm">
                    2017 AngelHack Holds Seventh Cohort of Their Pre-Accelerator
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  <Link to="https://web.archive.org/web/20171205004236/https://www.optimizely.com/disruptors/bryan-berger/">
                    2017 Digital Disruptors
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  <Link to="https://twitter.com/InVisionApp/status/787007891119169537">
                    2016 Inside Design: General Assembly
                  </Link>
                </Typography>
              </li>
            </ul>
          </section>
          <Divider />
          <section>
            <Typography variant="h2">Awards</Typography>
            <ul>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  <Link to="https://www.hackathon.io/projects/7914">
                    2015 1st Place United Nations + AngelHack Open Source
                    Hackathon
                  </Link>
                </Typography>
                <StyledTypography>
                  My team and I created "Sprout", a Crowdfunding Internship
                  platform to jumpstart youth employment worldwide.
                </StyledTypography>
              </li>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  <Link to="https://www.makerbot.com/stories/news/food-share-wins-ge-firstbuild-hack-a-thon/">
                    2015 1st Place MakerBot Thingiverse FirstBuild Hackathon
                  </Link>
                </Typography>
                <StyledTypography>
                  "The winning team, headed by Bryan Berger of NY Hackathons,
                  was Food Share, a way to display and share food from your
                  refrigerator with neighbors."
                </StyledTypography>
              </li>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  <Link to="https://www.behance.net/gallery/GS-P-BETA-Group-Adobe-Logo-Remix/14717247">
                    2014 Adobe Lights Installation Invitation
                  </Link>
                </Typography>
                <StyledTypography>
                  Invited to take part in the Adobe Lights Installation.
                  "Re-imagining Adobe's logo with 100 cubes of light, each one
                  reflecting the work of one artist in real time. A
                  representation of the global community of artists and
                  designers who work everyday."
                </StyledTypography>
              </li>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  2014 2nd Place at Hack The Bar (Pernod Ricard)
                </Typography>
                <StyledTypography>
                  Hardware + Software hack to help people find the right drink
                  and vibe - for the User, Bar and Brand.
                </StyledTypography>
              </li>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  2013 1st Place Adobe Game Jam NYC
                </Typography>
                <StyledTypography>
                  2 days to concept, design, develop, test and present a game
                  using the Stage3D GPU accelerated technology by Adobe.
                </StyledTypography>
              </li>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  <Link to="/archive/adobe-design-achivement-award">
                    2009 Adobe Design Achievement Award
                  </Link>
                </Typography>
                <StyledTypography>
                  The Adobe® Design Achievement Awards celebrate student and
                  faculty achievement reflecting the powerful convergence of
                  technology and the creative arts. The competition - which
                  showcases individual and group projects created with
                  industry-leading Adobe creative software - honors the most
                  talented and promising student graphic designers,
                  photographers, illustrators, animators, digital filmmakers,
                  developers and computer artists from the world's top
                  institutions of higher education.
                </StyledTypography>
              </li>
            </ul>
          </section>
          <Divider />
          <section>
            <Typography variant="h2">Misc</Typography>
            <ul>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  2022 FullSail Alumni Guest Panel
                </Typography>
                <StyledTypography>
                  My alma mater invited me to be a guest panelist at their
                  alumni event. It was a great event, I flew down to Orlando and
                  had a chance to talk with prospective students about my
                  journey and experiences.
                </StyledTypography>
              </li>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  <Link to="https://twitter.com/AngelHack/status/900048625887203329">
                    2017 Hackcelerator Guest Speaker
                  </Link>
                </Typography>
                <StyledTypography>
                  I had the opportunity to give a presentation about Design
                  Thinking, Branding, and UI design to students partaking in the
                  AngelHack Hackcelerator of 2017.
                </StyledTypography>
              </li>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  <Link to="http://canonhackathon.com/#judges">
                    2017 AngelHack &amp; CanonUSA - CodeCannon Judge
                  </Link>
                </Typography>
                <StyledTypography>
                  I had a great time helping Canon USA and AngelHack judge their
                  twoday photography based hackathon.
                </StyledTypography>
              </li>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  <Link to="https://www.slideshare.net/BryanBerger3/bryan-berger-on-distraction-free-design-sprints-at-design-driven-nyc">
                    2016 Design Driven NYC Meetup (Firstmark) - Design Sprints
                  </Link>
                </Typography>
                <StyledTypography>
                  I gave a presentation about Distraction Free Design Sprints.
                </StyledTypography>
              </li>
              <li>
                <Typography variant="h4" marginBottom={2}>
                  <Link to="https://credly.com/credit/19489796">
                    2011+ Adobe Design Achievement Award Judge
                  </Link>
                </Typography>
                <StyledTypography style={{ marginBottom: 0 }}>
                  I've been honored to be selected as a Judge for the Adobe
                  Design Achievement Awards from (2011 - 2019).
                </StyledTypography>
              </li>
            </ul>
          </section>
        </Container>
      </Page>
    </IndexLayout>
  )
}

export default InfoPage
